import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sms_survey_details_analytics_summary = _resolveComponent("sms-survey-details-analytics-summary")!
  const _component_sms_survey_details_analytics_chart = _resolveComponent("sms-survey-details-analytics-chart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_sms_survey_details_analytics_summary),
    _createVNode(_component_sms_survey_details_analytics_chart, { class: "mt-5" })
  ]))
}

import {
  defineComponent
} from 'vue'
import SmsSurveyDetailsAnalyticsSummary from '@/components/pages/services/smsSurvey/details/analytics/SmsSurveyDetailsAnalyticsSummary.vue'
import SmsSurveyDetailsAnalyticsChart from '@/components/pages/services/smsSurvey/details/analytics/SmsSurveyDetailsAnalyticsChart.vue'

export default defineComponent({
  components: {
    SmsSurveyDetailsAnalyticsSummary,
    SmsSurveyDetailsAnalyticsChart,
  },
})


import {
  defineComponent, ref
} from 'vue'
import CardsStats from '@/components/shared/templates/CardsStats.vue'
import type { CardStats } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    CardsStats,
  },
  setup() {
    const summary = ref<CardStats[]>([
      {
        title: 'Sent',
        value: 372,
      },
      {
        title: 'Delivered',
        value: 152,
      },
      {
        title: 'Replies',
        value: 148,
      },
      {
        title: 'Current cost',
        value: '$0.752',
      },
    ])

    return {
      summary,
    }
  },
})


import {
  defineComponent, ref
} from 'vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

export default defineComponent({
  components: {
    BarChart,
  },
  setup() {
    const data = ref({
      labels: ['Sent', 'Delivered', 'Question 1', 'Question 2', 'Question 3', 'Question 4'],
      datasets: [
        {
          data: [15, 8, 2, 12, 0, 3],
          backgroundColor: '#88b3e7',
          hoverBackgroundColor: '#88b3e7',
        },
      ],
    })

    return {
      data,
    }
  },
})
